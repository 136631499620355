
import { useLoaderData } from "@remix-run/react";
import AboutUs from "./features/AboutUs";
import BlogNews from "./features/BlogNews";
import ImageCarousel from "./features/ImageCarousel";
import OfferBanner from "./features/OfferBanner";
import References from "./features/References";
import { Services } from "./features/Services";
import Slogans from "./features/Slogans";
import { HizmetSubTitle } from "./hizmetlerimiz+/_index";
import { servicesData } from "./hizmetlerimiz+/features/hizmetlerData";

export const loader = () => {
  const title = HizmetSubTitle.replaceAll('ş', '<i>&#351;</i>').replaceAll('Ş', '<i>&#350;</i>').replaceAll('ğ', '<i>&#287;</i>').replaceAll('Ğ', '<i>&#286;</i>');
  return { servicesData, title };
};

export default function Page() {
  const { servicesData, title } = useLoaderData<typeof loader>();
  return (
    <div>
      <ImageCarousel />
      <Services hizmetler={servicesData} title={title} />
      <Slogans />
      <AboutUs />
      <BlogNews />
      <OfferBanner />
      <References />
    </div>
  )
}
