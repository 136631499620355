import { useEffect, useState } from "react";
import Slider from "react-slick";
import { ClientOnly } from "remix-utils/client-only";
import Container from "~/components/layout/Container";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import { Icon } from "~/components/ui/icon";


const blogPosts = [
  {
    title: "Türkiye'deki Burslar",
    excerpt: "Türkiye'deki bursların detaylarını bu sayfada bulabilirsiniz.",
    date: "2023-05-15",
    image: "/assets/Turkiye-Burslari.webp",
  }, {
    title: "Türkiye'de Dış Ticaret Personeli Olarak Çalışmak",
    excerpt: "Türkiye'de dış ticaret personeli olarak çalışmak isteyen yabancıların ilgisi duyduğu tüm bilgileri bu sayfada bulabilirsiniz.",
    date: "2023-05-15",
    image: "/assets/Turkiyede-dis-ticaret-personeli-olarak-calismak.webp",
  }, {
    title: "Yabancılar İçin Türkiye'de Öğrenim",
    excerpt: "Yabancıların Türkiye'de okul veya üniversitede öğrenim görmek istedikleri zaman ilgilerini bu sayfada bulabilirler.",
    date: "2023-05-15",
    image: "/assets/Yabancilar-icin-Turkiyede-Ogrenim.webp",
  },
  // Add more blog posts here
];

export const Dots = ({ dots }: any) => {
  return (
    <div className={Style.Dots}><ul> {dots} </ul></div>
  )
}
const Style = {
  Dots: `absolute -bottom-4 right-0 left-0 m-auto flex justify-center w-full
    [&_ul]:bottom-0 [&_ul]:right-5 [&_ul]:flex [&_ul]:w-fit
    [&_button]:w-4 [&_button]:h-4 [&_button]:mr-1 [&_button]:relative [&_button]:!text-[0px] [&_button]:cursor-pointer [&_button]:transition-0.6s [&_button]:border-2 [&_button]:border-[#20b1f5] [&_button]:shadow [&_button]:transition-[0.6s]
    [&_button]:focus:outline-none 
    [&_.slick-active_button]:border-2 [&_.slick-active_button]:border-[#144e85] [&_.slick-active_button]:w-3 [&_.slick-active_button]:h-3 [&_.slick-active_button]:mt-[-2px] [&_.slick-active_button]:transition-[0.6s]`,
  NextArrow: `absolute bottom-[45%] -right-10 left-auto z-10 text-0 p-1 border-none cursor-pointer transition-0.5s bg-transparent
    [&_svg]:w-8 [&_svg]:h-8 [&_svg]:text-[#20b1f5]
    hover:bg-[#ffffff55]
    focus:outline-none`,
  PrevArrow: `absolute bottom-[45%] -left-10 right-auto z-10 text-0 p-1 border-none cursor-pointer transition-0.5s bg-transparent
    [&_svg]:w-8 [&_svg]:h-8 [&_svg]:text-[#20b1f5]
    hover:bg-[#ffffff55]
    focus:outline-none`,
}

export const ArrowPrev = ({ ...props }: any) => {
  return (
    <button {...props} className={Style.PrevArrow}>
      <Icon name="ic:outline-keyboard-arrow-left" />
    </button>
  )
}

export const ArrowNext = ({ ...props }: any) => {
  return (
    <button {...props} className={Style.NextArrow}>
      <Icon name="material-symbols:chevron-right" />
    </button>
  )
}
export default function BlogNews() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <ArrowPrev />,
    nextArrow: <ArrowNext />,
    appendDots: (dots: any) => <Dots dots={dots} />,
  };

  if (!mounted) { return null; }

  return (
    <section className="py-16 ">
      <Container className="flex-wrap">
        <h2 className="text-3xl font-bold text-center mb-12">Blog / Bizden Haberler</h2>
        <div className="block justify-center w-full [&_.slick-track]:!flex [&_.slick-track]:!w-fit">
          <ClientOnly fallback={<div>Loading...</div>}>
            {() => (
              <Slider {...settings} className="pb-4">
                {blogPosts.map((post) => (
                  <div key={post.title} className="px-2 h-full">
                    <Card className="h-[404px]">
                      <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
                      <CardHeader>
                        <CardTitle>{post.title}</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p className="mb-2">{post.excerpt}</p>
                        <p className="text-sm text-gray-500">{post.date}</p>
                      </CardContent>
                    </Card>
                  </div>
                ))}
              </Slider>
            )}
          </ClientOnly>
        </div>
      </Container>
    </section>
  );
}