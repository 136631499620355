import Container from "~/components/layout/Container";

export default function AboutUs() {
  return (
    <section className="py-16">
      <Container className="container mx-auto px-4">
        <div className="md:w-1/2 mb-8 md:mb-0">
          <img src="/assets/utb-support-kurumsal.webp" alt="About UTB Support" className="rounded-lg shadow-lg" />
        </div>
        <div className="md:w-1/2 md:pl-12">
          <h2 className="text-[30px] font-bold mb-4 text-[#333333] font-raleway">UTB SUPPORT</h2>
          <strong className="text-[#555555] mb-4 flex w-full flex-col text-[22px] font-lato font-light before:content-[''] before:block before:w-10 after:content-[''] after:block after:w-10 after:h-[2px] after:bg-[#16437c] after:mt-4">Hakkımızda<br />
            Biz Kimiz? Neler Yaparız?</strong>
          <p className="mb-6 text-[#666666] flex leading-[27px] text-[15px] font-lato">
            UTB Support, 2022 yılında yenilenen vizyonu ve dinamik ekibiyle 20 yıllık tecrübesini daha da ileriye taşıyor. Firmamız, ithalat/ihracat, eğitim ve danışmanlık hizmetleri sunarak dış ticaret alanındaki bilgi ve birikimlerini iş ortaklarıyla paylaşmaktadır.
          </p>
          <a href="/hakkimizda" className="text-[#16437c] text-[15px] font-raleway font-semibold border border-[#16437c] px-4 py-2 rounded-md hover:bg-[#16437c] hover:text-white">DEVAMI</a>
        </div>
      </Container>
    </section>
  );
}